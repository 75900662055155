import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './HeroSection.module.css';
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimenstions';
import { MovingImage } from '@components/elements';
import Icon from '@iconify/react';
import arrowRightIcon from '@iconify/icons-feather/arrow-right';
import arrowLeftIcon from '@iconify/icons-feather/arrow-left';
import Link from 'next/link';
import { useMobileDetect } from '@hooks';
import { APP_STORE, PLAY_STORE } from '@constant';
import { GetMobileAppModal } from '../GetMobileAppModal';
import { useTranslations } from 'next-intl';

type HeroSectionProps = {
  className?: string;
  onImageLoad?: () => void;
};

const cdnPrefix = process.env.NEXT_PUBLIC_CDN_DOMAIN || '';

const variants = {
  enter: (direction: number) => {
    return {
      opacity: 0,
    };
  },
  center: {
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      opacity: 0,
    };
  },
};

const yPhoneScale = [
  [0.3, 0.8, 2.04],
  [0.3, 0.72, 1.85],
  [0.3, 0.6, 1.7],
];

const yTextScale = [
  [-0.12, 1.12],
  [-0.12, 1.0],
  [-0.08, 1.02],
];

const yPhoneDesktopScale = [
  [-0.44, 0.78, 1.85],
  [-0.1, 0.7, 1.8],
  [-0.1, 0.7, 1.8],
];

export const HeroSection = ({ className, onImageLoad = () => {} }: HeroSectionProps) => {
  const ref = useRef(null);
  const translations = useTranslations('landingPage.heroSection');
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start'],
  });
  const { height, width } = useWindowDimensions();

  const [phonePage, setPhonePage] = useState(0);

  const isPhone = height > width;

  const phoneHeightTier = height < 700 ? 0 : height < 900 ? 1 : 2;

  const desktopHeightTier = height <= 700 ? 0 : height <= 800 ? 1 : 2;

  const y = useTransform(
    scrollYProgress,
    // Map y from these values:
    [0, 0.6, 1],
    // Into these values:
    [
      -height *
        (isPhone ? yPhoneScale[phoneHeightTier][0] : yPhoneDesktopScale[desktopHeightTier][0]),
      height *
        (isPhone ? yPhoneScale[phoneHeightTier][1] : yPhoneDesktopScale[desktopHeightTier][1]),
      height *
        (isPhone ? yPhoneScale[phoneHeightTier][2] : yPhoneDesktopScale[desktopHeightTier][2]),
    ]
  );

  const yText = useTransform(
    scrollYProgress,
    // Map y from these values:
    [0.6, 1],
    // Into these values:
    [
      height * (isPhone ? yTextScale[phoneHeightTier][0] : 0),
      height * (isPhone ? yTextScale[phoneHeightTier][1] : 1.2),
    ]
  );

  const yButton = useTransform(
    scrollYProgress,
    [0.6, 1],
    [height * (isPhone ? -0.25 : -0.2), height * (isPhone ? 0.985 : 0.9)]
  );

  const opacityText = useTransform(scrollYProgress, [0.4, 0.6], [0, 1]);

  const scale = useTransform(
    scrollYProgress,
    // Map scale from these values:
    [0, 0.5],
    // Into these values:
    [1, 0.6]
  );

  // Need this for consistent phone image change
  const [, setHookedYPosition] = useState(0);
  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setHookedYPosition(latest);
  });

  const phoneImageIndex = scrollYProgress.get() < 0.4 ? 0 : 1;

  const phoneImage = useMemo(
    () =>
      [
        '/assets/images/landing_page/phone.webp',
        [
          '/assets/images/landing_page/phone-tcou.webp',
          '/assets/images/landing_page/phone-mlt.webp',
          '/assets/images/landing_page/phone-hangout.webp',
        ][phonePage],
      ][phoneImageIndex],
    [phonePage, phoneImageIndex]
  );

  const phoneLabel = ['The Card of Us', 'Most Likely To', 'Hangout!'][phonePage];
  const phoneTextColor = ['text-rose-700', 'text-red-500', 'text-[#00AA9C]'][phonePage];
  const phoneSectionBgColor = ['bg-pink-600', 'bg-red-500', 'bg-[#00B1A3]'][phonePage];
  const rightMessage = [
    translations('promotion.tcou'),
    translations('promotion.mlt'),
    translations('promotion.hangout'),
  ][phonePage];

  const { isAndroid, isIos } = useMobileDetect();

  const [showMobileAppModal, setShowMobileAppModal] = useState(false);

  const mobileAppLink = isAndroid() ? PLAY_STORE : isIos() ? APP_STORE : '';
  const onClickGetApp = useCallback(() => {
    if (mobileAppLink) return;
    setShowMobileAppModal(true);
  }, [mobileAppLink, setShowMobileAppModal]);

  return (
    <div
      ref={ref}
      className={`${className} ${styles.root} relative flex flex-col justify-between items-center gap-y-12 gap-x-6 lg:gap-x-16 w-100 transition-all`}
    >
      <div className="relative flex flex-col justify-between items-center gap-y-12 gap-x-6 lg:gap-x-16 min-h-screen min-w-full">
        <div className="flex flex-col self-center items-center w-full align text-secondary max-w-xs md:max-w-none md:my-20 lg:my-40 2xl:my-48 ">
          <h2 className="text-center text-md sm:text-xl font-semibold md:text-md xl:text-lg 2xl:text-xl z-20">
            {translations('description.paragraph1')} <br />
            {translations('description.paragraph2')}
          </h2>
          <h1 className="flex w-full items-center flex-col text-secondary text-[40px] leading-[1.2] md:text-[50px] lg:text-[55px] 2xl:text-6xl font-madeawelier font-extrabold">
            This is Socles
          </h1>
          <div className="flex justify-center items-center mt-8">
            <Link href={mobileAppLink} passHref>
              <button
                className="md:mt-18 px-6 py-3 font-semibold text-white text-md bg-secondary rounded-full tracking-wide z-50 relative"
                onClick={onClickGetApp}
              >
                {translations('ctaButton')}
              </button>
            </Link>
          </div>
        </div>
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/boom.webp'}
          alt="Boom"
          initialX={width * (isPhone ? 0.4 : 0.2)}
          initialY={height * 0.5}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/card-dice.webp'}
          alt="Card and Dice"
          initialX={width * -0.4}
          initialY={height * 0.5}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/hand.webp'}
          alt="Hand"
          initialX={width * -0.4}
          initialY={height * (isPhone ? -0.1 : 0)}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/present.webp'}
          alt="Present"
          initialX={width * -0.24}
          initialY={height * (isPhone ? -0.2 : 0)}
          style={{ width: isPhone ? '8rem' : '10rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/heart.webp'}
          alt="Heart"
          initialX={width * 0.24}
          initialY={height * (isPhone ? -0.3 : 0.05)}
          style={{ width: isPhone ? '10rem' : '15rem', zIndex: 10 }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/hat.webp'}
          alt="Hat"
          initialX={width * 0.4}
          initialY={height * (isPhone ? 0.1 : 0.05)}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/cat.webp'}
          alt="Cat"
          initialX={width * 0.35}
          initialY={height * 0.3}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <MovingImage
          src={cdnPrefix + '/assets/images/landing_page/hero/fire.webp'}
          alt="Fire"
          initialX={width * -0.24}
          initialY={height * (isPhone ? 0.3 : 0.4)}
          style={{ width: isPhone ? '10rem' : '15rem' }}
        />
        <AnimatePresence initial={false}>
          <motion.img
            src={cdnPrefix + phoneImage}
            style={{
              position: 'absolute',
              bottom: 0,
              width: isPhone ? '72%' : 0.15 * width + 0.24 * height,
              y,
              scale: scale,
            }}
            className=""
            alt="phone"
            variants={variants}
            key={`${phoneImageIndex}-${phonePage}`}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              opacity: { duration: 0.5 },
            }}
          />
        </AnimatePresence>
      </div>
      <div className="relative flex flex-col justify-between items-center space-y-24 gap-x-6 lg:gap-x-16 min-h-[80vh]">
        <div className="flex flex-col md:flex-row self-center items-center w-full columns-3 max-w-xs md:max-w-none space-y-96 md:space-y-0">
          <motion.h1
            style={{ y: yText, opacity: opacityText }}
            transition={{
              opacity: { duration: 0.5 },
            }}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            key={phonePage}
            className={`flex w-full items-center flex-col ${phoneTextColor} text-[34px] leading-[1.2] md:text-[55px] mb-4 md:mb-0 font-madeawelier font-extrabold`}
          >
            {translations('promotion.leftMessage')}
          </motion.h1>
          <div className="w-full md:block hidden text-center"></div>
          <motion.h2
            style={{ y: yText, opacity: opacityText }}
            className={`font-nunito text-md lg:text-1.5md xl:text-lg 2xl:text-xl ${phoneTextColor} text-center md:text-left`}
          >
            {rightMessage}
          </motion.h2>
        </div>
        <motion.div
          className="bg-gradient-to-l from-white to-orange-50 rounded-[90px] shadow-phone-nav flex p-3 justify-center items-center space-x-3"
          style={{ y: yButton }}
        >
          <button
            className={`rounded-full p-2 ${phoneSectionBgColor}`}
            disabled={phonePage === 0}
            onClick={() => setPhonePage((page) => page - 1)}
          >
            <Icon icon={arrowLeftIcon} color="#fff" />
          </button>
          <p className={`${phoneTextColor}`}>
            {translations('promotion.playButton')} {phoneLabel}
          </p>
          <button
            className={`rounded-full p-2 ${phoneSectionBgColor}`}
            disabled={phonePage === 2}
            onClick={() => setPhonePage((page) => page + 1)}
          >
            <Icon icon={arrowRightIcon} color="#fff" />
          </button>
        </motion.div>
      </div>
      {showMobileAppModal && (
        <GetMobileAppModal
          closeModal={() => setShowMobileAppModal(false)}
          show={showMobileAppModal}
        />
      )}
    </div>
  );
};
