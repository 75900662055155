import { useRef } from 'react';
import styles from './HeroSection.module.css';
import Image from 'next/image';
import useWindowDimensions from 'hooks/useWindowDimenstions';
import { useTranslations } from 'next-intl';

type FeatureSectionProps = {
  className?: string;
  onImageLoad?: () => void;
};

const cdnPrefix = process.env.NEXT_PUBLIC_CDN_DOMAIN || '';

export const FeatureSection = ({ className, onImageLoad = () => {} }: FeatureSectionProps) => {
  const ref = useRef(null);
  const { height, width } = useWindowDimensions();
  const translations = useTranslations('landingPage.features');

  const isPhone = height > width;

  const circleComponent = (className?: string) => (
    <div
      className={`relative p-6 2xl:p-12 space-y-2 text-[#441590] rounded-3xl shadow-lg bg-[#B394E4] grid items-center row-span-1 col-span-2 ${className}`}
    >
      <div className="flex flex-col space-y-4 2xl:space-y-8 w-full">
        <p className="text-md 2xl:text-lg text-left leading-5 w-2/5 font-nunito font-medium 2xl:leading-[1.3]">
          {translations('circleDescription')}
        </p>
        <h2 className="text-xl 2xl:text-3xl text-left font-madeawelier font-bold">Circle</h2>
      </div>
      <Image
        width={Math.min((isPhone ? 0.18 : 0.13) * width, 184)}
        height={Math.min((isPhone ? 0.19 : 0.162) * width, 230)}
        src={cdnPrefix + '/assets/images/landing_page/feature/circle-info.webp'}
        alt="Circle Info"
        className="absolute bottom-1/4 right-20 z-20"
      />
      <Image
        width={Math.min((isPhone ? 0.2 : 0.16) * width, 226)}
        height={Math.min((isPhone ? 0.25 : 0.2) * width, 283)}
        src={cdnPrefix + '/assets/images/landing_page/feature/circle-info2.webp'}
        alt="Circle Info 2"
        className="absolute bottom-0 right-28 z-10"
      />
      <Image
        width={Math.min((isPhone ? 0.24 : 0.12) * width, 171)}
        height={Math.min((isPhone ? 0.2 : 0.1) * width, 148)}
        src={cdnPrefix + '/assets/images/landing_page/feature/interest.webp'}
        alt="Interest"
        className="absolute bottom-3 -right-1/10 z-30"
      />
    </div>
  );

  return (
    <div
      ref={ref}
      className={`${className} ${styles.root} relative flex flex-col justify-between items-center gap-y-12 gap-x-6 lg:gap-x-16 w-100`}
    >
      <div className="relative flex flex-col justify-between items-center gap-y-12 gap-x-6 lg:gap-x-16 min-h-screen min-w-full">
        <div className="flex flex-col self-center items-center w-full align text-secondary lg:max-w-3xl xl:max-w-4xl 2xl:max-w-7xl">
          <h1 className="flex w-full items-center flex-col text-secondary text-[40px] leading-[1.2] md:text-[55px] mb-8 md:mb-24 font-madeawelier font-extrabold">
            {translations('title')}
          </h1>
          <div className="w-full md:grid grid-rows-2 grid-cols-3 grid-flow-row gap-4 space-y-4 md:space-y-0 font-mono text-white text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg px-6">
            {circleComponent('md:hidden')}
            <div className="relative p-6 2xl:p-12 space-y-4 2xl:space-y-8 rounded-3xl shadow-lg bg-[#F682A5] text-[#9D003B] md:grid place-content-start row-span-2 text-start">
              <h2 className="text-xl 2xl:text-3xl leading-8 font-madeawelier font-bold">
                The Card of Us
              </h2>
              <p className="text-md 2xl:text-lg font-nunito font-medium 2xl:leading-[1.3]">
                {translations('tcouDescription')}
              </p>
              <Image
                width={Math.min(0.274 * width, 380)}
                height={Math.min(0.313 * width, 1420)}
                src={cdnPrefix + '/assets/images/landing_page/feature/cards.webp'}
                alt="cards"
                className="absolute -bottom-20 md:block hidden"
              />
              <Image
                width={Math.min(0.274 * width, 380)}
                height={Math.min(0.313 * width, 1420)}
                src={cdnPrefix + '/assets/images/landing_page/feature/cards.webp'}
                alt="cards"
                className="absolute -bottom-16 -right-4 z-10 md:hidden"
              />
              <Image
                width={Math.min(0.06 * width, 120)}
                height={Math.min(0.06 * width, 120)}
                src={cdnPrefix + '/assets/images/landing_page/feature/person1.webp'}
                alt="Person 1"
                className="absolute bottom-0 md:bottom-1/2 right-1/3 md:-left-1/10"
              />
              <Image
                width={Math.min(0.05 * width, 80)}
                height={Math.min(0.05 * width, 80)}
                src={cdnPrefix + '/assets/images/landing_page/feature/person2.webp'}
                alt="Person 2"
                className="absolute 2xl:bottom-1/2 bottom-64 left-1/4 hidden md:block"
              />
              <Image
                width={Math.min((isPhone ? 0.066 : 0.056) * width, 80)}
                height={Math.min((isPhone ? 0.066 : 0.056) * width, 80)}
                src={cdnPrefix + '/assets/images/landing_page/feature/person2.webp'}
                alt="Person 2"
                className="absolute bottom-1/3 right-0 md:hidden"
              />
              <Image
                width={Math.min(0.1 * width, 200)}
                height={Math.min(0.1 * width, 150)}
                src={cdnPrefix + '/assets/images/landing_page/feature/start-play.webp'}
                alt="Start Play"
                className="absolute bottom-1/2 -right-1/10 z-50 md:block hidden"
              />
              <Image
                width={Math.min(0.262 * width, 140)}
                height={Math.min(0.262 * width, 140)}
                src={cdnPrefix + '/assets/images/landing_page/feature/start-play.webp'}
                alt="Start Play"
                className="absolute top-1/10 -right-4 z-50 md:hidden"
              />
              <Image
                width={Math.min((isPhone ? 0.062 : 0.036) * width, 60)}
                height={Math.min((isPhone ? 0.062 : 0.036) * width, 60)}
                src={cdnPrefix + '/assets/images/landing_page/feature/person3.webp'}
                alt="Person 3"
                className="absolute bottom-0 md:bottom-1/3 md:-left-1/10 left-1/2"
              />
            </div>
            {circleComponent('hidden md:flex')}
            <div className="p-6 2xl:p-12 space-y-4 2xl:space-y-8 text-[#00625A] leading-8 rounded-3xl shadow-lg bg-[#70DDC9] relative grid place-content-start row-span-1 col-span-1 md:aspect-square pb-24 md:pb-0">
              <Image
                alt="Bg Trees"
                className="rounded-3xl absolute bottom-0 right-0 z-10 h-28"
                src={`${cdnPrefix}/assets/images/landing_page/bg-trees.webp`}
                width={500}
                height={500}
              />
              <h2 className="text-xl 2xl:text-3xl text-left font-madeawelier font-bold">Hangout</h2>
              <p className="text-md 2xl:text-lg text-left leading-5 w-3/4 font-nunito font-medium 2xl:leading-[1.3]">
                {translations('hangoutDescription')}
              </p>
              <Image
                width={Math.min((isPhone ? 0.36 : 0.1) * width, 171)}
                height={Math.min((isPhone ? 0.3 : 0.1) * width, 148)}
                src={cdnPrefix + '/assets/images/landing_page/feature/hangout-paper.webp'}
                alt="Hangout!"
                className="absolute bottom-0 md:bottom-3 right-1/10 md:-right-1/10 z-30"
              />
            </div>
            <div className="relative p-6 2xl:p-12 text-[#986100] leading-8 rounded-3xl shadow-lg space-y-3 bg-[#F7C263] grid row-span-1 col-span-1 md:aspect-square">
              <h2 className="text-xl 2xl:text-3xl leading-8 2xl:leading-[1.3] text-left w-2/3 font-madeawelier font-bold">
                Most Likely To
              </h2>
              <p className="text-md 2xl:text-lg text-left leading-5 w-2/3 font-nunito font-medium 2xl:leading-[1.3]">
                {translations('mltDescription')}
              </p>
              <Image
                width={Math.min((isPhone ? 0.24 : 0.1) * width, 170)}
                height={Math.min((isPhone ? 0.24 : 0.1) * width, 173)}
                src={cdnPrefix + '/assets/images/landing_page/feature/user-card.webp'}
                alt="User Card"
                className="absolute -bottom-4 md:-right-1/5 right-0"
              />
              <Image
                width={Math.min((isPhone ? 0.2 : 0.1) * width, 141)}
                height={Math.min((isPhone ? 0.16 : 0.083) * width, 118)}
                src={cdnPrefix + '/assets/images/landing_page/feature/tap-to-vote.webp'}
                alt="Tap to Vote"
                className="absolute md:top-5 top-1/3 md:-right-1/5 right-0"
              />
              <Image
                width={Math.min((isPhone ? 0.16 : 0.084) * width, 120)}
                height={Math.min((isPhone ? 0.056 : 0.028) * width, 40)}
                src={cdnPrefix + '/assets/images/landing_page/feature/voted-by.webp'}
                alt="Voted by"
                className="absolute top-4 md:-top-4 md:-right-1/5 right-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
