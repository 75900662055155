import { memo, useCallback, useMemo } from 'react';

import { Modal } from '@components/modules';
import { APP_STORE, PLAY_STORE } from '@constant';
import QRCode from 'react-qr-code';
import React from 'react';

type GetMobileAppModalProps = {
  closeModal: () => void;
  show: boolean;
};

enum MobileOs {
  ios,
  android,
}

export const GetMobileAppModal = memo(function GetMobileAppModal({
  closeModal,
  show,
}: GetMobileAppModalProps) {
  const close = useCallback(() => closeModal(), [closeModal]);

  const [fadeOut, setFadeOut] = React.useState(false);

  const [os, setOs] = React.useState(MobileOs.ios);
  React.useEffect(() => {
    if (show) return;

    setFadeOut(true);
    setTimeout(() => setFadeOut(false), 75);
  }, [show]);

  return (
    <Modal
      fadeOut={fadeOut}
      closeModal={close}
      height="500px"
      width="500px"
      className="!rounded-[32px]"
    >
      <div className="w-full h-full p-5 flex flex-col space-y-4 justify-center items-center">
        <h1 className="font-nunito font-medium text-xl text-center">
          Scan and get the mobile app!
        </h1>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={os == MobileOs.ios ? APP_STORE : PLAY_STORE}
          viewBox={`0 0 256 256`}
        />
        <div
          className="flex items-center space-x-1 bg-gray-2 bg-opacity-30 rounded-md px-2"
          style={{
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(5px)',
            border: '1px solid rgba(255, 255, 255, 0.3);',
          }}
        >
          <button
            onClick={() => setOs(MobileOs.ios)}
            className={os === MobileOs.ios ? 'text-primary' : ''}
          >
            iOS
          </button>
          <p>|</p>
          <button
            onClick={() => setOs(MobileOs.android)}
            className={os === MobileOs.android ? 'text-primary' : ''}
          >
            Android
          </button>
        </div>
      </div>
    </Modal>
  );
});
