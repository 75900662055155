import { memo, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import styles from './modal.module.css';

type ModalProps = {
  children: ReactNode;
  closeModal: () => void;
  fadeOut: boolean;
  bg?: string;
  width?: string;
  height?: string;
  noscrollbar?: boolean;
  className?: string;
};

export const Modal = memo(function Modal({
  noscrollbar = false,
  children,
  closeModal,
  fadeOut,
  bg,
  width = 'auto',
  height = 'auto',
  className = '',
}: ModalProps) {
  const paddingStyle = useMemo(
    () => `calc((100vh - ${height}) / 2) calc((100vw - ${width}) / 2)`,
    [width, height]
  );

  return createPortal(
    <div
      style={{ padding: paddingStyle }}
      className={`${noscrollbar ? 'noscroll' : 'customscroll'} ${styles.modal} ${
        fadeOut && styles.modalOut
      } `}
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div
        className={
          !fadeOut
            ? `${styles.modalIn} ${styles.modalcontent} ${className}`
            : `${styles.modalcontent} ${className}`
        }
        style={{
          backgroundColor: bg ? bg : 'white',
          width: width,
          height: height,
          margin: 12,
        }}
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
});
