import { memo, useCallback, useState } from 'react';

import { useAuthUser } from 'next-firebase-auth';
import { BroadcastChannel } from 'broadcast-channel';
import ReactGA from 'react-ga';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import Link from 'next/link';
import { WebView } from '@utils/WebView';
import { SoclesLogo } from './SoclesLogo';
import { useUser } from '@context';
import { SoclesLogoFlex } from './SoclesLogoFlex';
import { useMobileDetect } from '@hooks';
import { GetMobileAppModal } from '@components/modules/GetMobileAppModal';
import { PLAY_STORE, APP_STORE } from '@constant';

type PublicHeaderProps = {
  className?: string;
  dark?: boolean;

  fullPage?: boolean;
  shadow?: boolean;

  path?: string;
};

export const PublicHeaderV2 = memo(function PublicHeader({
  className = 'bg-transparent',
  dark = false,
  fullPage = false,
  shadow = false,
  path = '',
}: PublicHeaderProps) {
  const AuthUser = useAuthUser();
  const signOut = useCallback(() => {
    const channel = new BroadcastChannel('signout');
    channel.postMessage(AuthUser.id);
    channel.close();

    setTimeout(() => {
      if (!AuthUser?.id) return;

      AuthUser.signOut();
      window.location.href = '/login';
    }, 1000);
  }, [AuthUser.id]);

  const user = useUser();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isAndroid, isIos } = useMobileDetect();

  const [showMobileAppModal, setShowMobileAppModal] = useState(false);

  const mobileAppLink = isAndroid() ? PLAY_STORE : isIos() ? APP_STORE : '';

  const onClickGetApp = () => {
    if (mobileAppLink) return;
    setShowMobileAppModal(true);
  };

  const toggleDrawer = () => setDrawerOpen((open) => !open);
  return (
    <nav className="w-full flex justify-center fixed top-0 z-[500]">
      {/* Desktop header */}
      <div
        className={`${className} fixed md:relative z-50 hidden md:flex flex-row items-center py-6 px-4 md:px-16 lg:px-26 lg:pt-8 lg:pb-5 w-full`}
        style={fullPage ? { justifyContent: 'space-between' } : { justifyContent: 'space-between' }}
      >
        {WebView.canSendMessage() ? (
          <div className="flex flex-row cursor-pointer">
            <SoclesLogoFlex />
          </div>
        ) : (
          <Link href="/" passHref className="flex flex-row cursor-pointer">
            <SoclesLogoFlex />
          </Link>
        )}

        <div className="flex justify-center items-center gap-x-6">
          <Link
            href="/aboutus"
            passHref
            className="flex flex-row cursor-pointer text-secondary font-bold"
          >
            About us
          </Link>
          <Link href={mobileAppLink} passHref>
            <button
              className="md:mt-18 px-6 py-3 font-semibold text-white text-md bg-secondary rounded-full tracking-wide"
              onClick={onClickGetApp}
            >
              Get the App
            </button>
          </Link>
        </div>
      </div>
      {showMobileAppModal && (
        <GetMobileAppModal
          closeModal={() => setShowMobileAppModal(false)}
          show={showMobileAppModal}
        />
      )}

      {/* Mobile header */}
      <div
        style={{ zIndex: 100 }}
        className={`${className} fixed md:hidden flex flex-row justify-between p-6 w-full bg-gray-5`}
      >
        {WebView.canSendMessage() ? (
          <div className="flex flex-row cursor-pointer">
            <SoclesLogoFlex />
            {!!user?.username && (
              <div className="ml-1 self-center font-semibold">· @{user.username}</div>
            )}
          </div>
        ) : (
          <Link href="/" passHref className="flex flex-row cursor-pointer">
            <SoclesLogoFlex />
            {!!user?.username && (
              <div className="ml-1 self-center font-semibold">
                · @
                {user.username.length > 10 ? user.username.substring(0, 7) + '...' : user.username}
              </div>
            )}
          </Link>
        )}

        <img
          className={`cursor-pointer ${dark && 'invert'}`}
          alt="Open drawer"
          src="/assets/icons/ic_drawer.svg"
          onClick={toggleDrawer}
        />
      </div>

      {/* Mobile drawer */}
      <Drawer
        className="md:hidden flex flex-col p-6"
        open={drawerOpen}
        onClose={toggleDrawer}
        direction="top"
        size="100vh"
      >
        <div className="flex flex-row justify-between">
          {WebView.canSendMessage() ? (
            <div className="flex flex-row cursor-pointer">
              <SoclesLogoFlex />
            </div>
          ) : (
            <Link href="/" passHref className="flex flex-row cursor-pointer">
              <SoclesLogoFlex />
            </Link>
          )}

          <img
            className="cursor-pointer"
            alt="Close drawer"
            src="/assets/icons/ic_close.svg"
            onClick={toggleDrawer}
          />
        </div>

        <div className="flex flex-col gap-6 mt-10">
          {AuthUser.id ? (
            <button className="cursor-pointer" onClick={signOut}>
              Logout
            </button>
          ) : WebView.canSendMessage() ? (
            <Link href="/login" passHref className="cursor-pointer">
              Login
            </Link>
          ) : (
            <>
              {/* <Link href="/app-early-access" passHref>
                <a className={`${path === 'app-early-access' && 'text-primary'} cursor-pointer`}>
                  Early Access
                </a>
              </Link> */}

              <Link
                href="/aboutus"
                passHref
                className={`${path === 'aboutus' && 'text-primary'} cursor-pointer`}
              >
                About Us
              </Link>

              <Link href={mobileAppLink} passHref>
                <button
                  className="md:mt-18 px-6 py-3 font-semibold text-white text-md bg-secondary rounded-full tracking-wide"
                  onClick={onClickGetApp}
                >
                  Get the App
                </button>
              </Link>
            </>
          )}
        </div>
      </Drawer>
    </nav>
  );
});
