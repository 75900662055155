import { useEffect } from 'react';
import {
  MotionStyle,
  cubicBezier,
  motion,
  useMotionValue,
  useScroll,
  useTransform,
} from 'framer-motion';

type ImageProps = {
  src: string;
  alt: string;
  initialX: number;
  initialY: number;
  style?: MotionStyle;
};

export const MovingImage: React.FC<ImageProps> = ({ src, alt, initialX, initialY, style }) => {
  const ease = cubicBezier(0.42, 0, 0.58, 1);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const { scrollYProgress } = useScroll();

  const x = useTransform([mouseX, scrollYProgress], ([mouseX, scrollYProgress]) => {
    const cursorOffset = ((mouseX as number) - window.innerWidth / 2) * 0.05;
    const scrollOffset = (scrollYProgress as number) * window.innerWidth * 1.2;
    return initialX + cursorOffset + (initialX > 0 ? scrollOffset : -scrollOffset);
  });

  const y = useTransform([mouseY, scrollYProgress], ([mouseY, scrollYProgress]) => {
    const cursorOffset = ((mouseY as number) - window.innerHeight / 2) * 0.05;
    const scrollOffset = (scrollYProgress as number) * window.innerHeight;
    return initialY + cursorOffset - scrollOffset;
  });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      mouseX.set(event.clientX);
      mouseY.set(event.clientY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseX, mouseY]);

  return (
    <motion.img
      src={src}
      alt={alt}
      style={{ position: 'absolute', width: '20%', x, y, zIndex: 100, ...style }}
      transition={{
        type: 'spring',
        damping: 50,
        stiffness: 200,
        mass: 1,
        duration: 1,
        ease,
        delay: 0.3,
      }}
    />
  );
};

export default MovingImage;
