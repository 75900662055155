import styles from './MobileAppSection.module.css';
import { AspectRatioImage } from '@components/elements';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

type MobileAppSectionProps = {
  className?: string;
};

const cdnPrefix = process.env.NEXT_PUBLIC_CDN_DOMAIN || '';

export const MobileAppSection = ({ className }: MobileAppSectionProps) => {
  const translations = useTranslations('landingPage.mobile');
  return (
    <div className="bg-gradient-to-b from-peach via-gray-4 to-gray-5 w-full flex justify-center px-12">
      <div
        className={`${className} container flex flex-col mb-24 md:flex-row items-center gap-12 sm:gap-4 md:gap-12`}
      >
        <div className="flex flex-col items-center md:items-start w-full sm:w-4/5 md:w-1/2">
          <h2 className="mt-6 text-primary text-md lg:text-1.5md font-semibold text-center md:text-left">
            {translations('almostOpen')}{' '}
          </h2>
          <h1 className="relative font-petrona text-lg lg:text-1.5xl font-bold leading-tight text-center md:text-left mt-16 md:mt-0">
            {translations('findOut')}{' '}
            <span className="relative">
              <>{translations('highlight')}</>
              <div className={styles.decor01}>
                <img
                  alt=""
                  style={{ margin: 'auto' }}
                  src={cdnPrefix + '/assets/images/landing/04/decor-01.svg'}
                />
              </div>
            </span>{' '}
            <span className="relative">
              <>{translations('connect')}</>
              <div className={styles.decor02}>
                <img alt="" src={cdnPrefix + '/assets/images/landing/04/decor-02.svg'} />
              </div>
            </span>{' '}
          </h1>
          {/* <h2 className="mt-4 text-primary text-md lg:text-1.5md font-semibold text-center md:text-left">
            Everyone is unique
          </h2>
          <p className="mt-2 text-md lg:text-1.5md font-normal text-center md:text-left">
            Persona quiz is here!
          </p>
          <div className="flex flex-row justify-center md:justify-start gap-4 mt-6 w-full">
            <Link href="/persona-quiz" passHref>
              <button className=" md:mt-18 px-6 py-3 font-semibold text-white text-md bg-primary rounded-full tracking-wide">
                Try Now
              </button>
            </Link>
          </div> */}
        </div>

        <AspectRatioImage
          className="w-full sm:w-4/5 md:w-1/2"
          imageClassName={styles.image}
          alt="Perfectly matched social circle"
          src={cdnPrefix + '/assets/images/landing/landing-feature-1.webp'}
          ratio={532 / 351}
        >
          <div className={styles.decor03}>
            <div className="transform scale-50 md:scale-75 lg:scale-100">
              <img alt="" src={cdnPrefix + '/assets/images/landing/02/decor-03.svg'} />
            </div>
          </div>

          <div className={styles.decor04}>
            <div className="transform scale-50 md:scale-75 lg:scale-100">
              <img alt="" src={cdnPrefix + '/assets/images/landing/02/decor-04.svg'} />
            </div>
          </div>
        </AspectRatioImage>
      </div>
    </div>
  );
};
