import { useState } from 'react';

import Link from 'next/link';
import { motion, useScroll, useTransform } from 'framer-motion';

import { HeroSection } from './HeroSection';
import { PublicHeaderV2 } from '@components/elements/PublicHeaderV2';
import { FeatureSection } from './FeatureSection';
import { MobileAppSection } from '../LandingPage/MobileAppSection';

export const LandingPageV2 = () => {
  const [imageLoad, setImageLoad] = useState(false);
  const { scrollYProgress } = useScroll();

  // const backgroundColor = useTransform(
  //   scrollYProgress,
  //   // Map x from these values:
  //   [0, 0.75],
  //   // Into these values:
  //   ['#FFF6E9', '#FFF6E9']
  // );

  return (
    <div className="overflow-hidden relative select-none flex flex-col items-center">
      <PublicHeaderV2 fullPage className="bg-transparent flex-grow" />

      <motion.div
        className="flex justify-center items-center flex-col pt-64 mt-0 md:pt-20 w-full"
        style={{ backgroundColor: '#FFF6E9' }}
      >
        <HeroSection className="w-full md:px-24" onImageLoad={() => setImageLoad(true)} />

        <div className="h-screen"></div>

        <FeatureSection
          className="md:my-4 lg:my-16 2xl:my-36 w-full container mt-20"
          onImageLoad={() => setImageLoad(true)}
        />
        <MobileAppSection className="md:mt-20 lg:mt-32 mx-auto pb-16 w-full" />
      </motion.div>
    </div>
  );
};
