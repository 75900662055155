import { NextPage, NextPageContext } from 'next';

import Head from 'next/head';
import { PublicFooter } from '@components/elements';
import { AuthAction, withAuthUser } from 'next-firebase-auth';
import { LandingPageV2 } from '@components/modules/LandingPageV2';

const Landing: NextPage = () => {
  return (
    <>
      <Head>
        <title>Socles · Fun Together Social Network</title>
        <meta
          name="description"
          content="Join Socles and be a part of the exclusive community and get new circles full of distinguished individuals based on your shared interests."
        />
      </Head>
      <LandingPageV2 />
      <PublicFooter />
    </>
  );
};

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.RENDER,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Landing);

export async function getStaticProps(context: NextPageContext) {
  const locale = context.locale === 'default' ? 'en' : context.locale;
  return {
    props: {
      // You can get the messages from anywhere you like. The recommended
      // pattern is to put them in JSON files separated by locale and read
      // the desired one based on the `locale` received from Next.js.
      messages: (await import(`../i18n/${locale}.json`)).default,
    },
  };
}
